<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="$t('reports.name.px-1')" />
							<div class="card__content--body index-page">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="search-main">
												<div class="row">
													<div class="col-md-6">
														<div class="title mb-2">{{ $t('placeholder.search_by_name') }}</div>
														<div>
															<label class="search__label">
																<input
																	type="text"
																	value=""
																	name="filter_search"
																	v-model="v_model_name_search"
																/>

																<button>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</button>
															</label>
														</div>
														<!-----tsble-filter-search------>
													</div>
													<!-----col------>

													<div class="col-md-6">
														<div class="title mb-2">
															{{ $t('placeholder.search_by_iin_or_bin') }}
														</div>

														<div>
															<label class="search__label">
																<input type="text" v-model="v_model_iin_search" />

																<button>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</button>
															</label>
														</div>
														<!-----search------>
													</div>
													<!-----col------>
												</div>
												<!-----row------>

												<div class="row mt-4">
													<div class="col-md-6">
														<div class="calendar">
															<quarter
																:title="$t('headers.send_period')"
																ref="quarter"
																:placeholder="$t('placeholder.choose')"
																:inputYears="true"
																:allOptions="true"
																v-on:update:modelValue="quarter_value = $event"
															>
															</quarter>
														</div>
													</div>
													<div class="col-md-6">
														<pond
															:title="$t('headers.pond')"
															:required="true"
															ref="pond"
															:allOptions="true"
															:placeholder="$t('placeholder.choose')"
															v-on:update:modelValue="v_model_pond_search = $event"
														>
														</pond>
													</div>
												</div>
											</div>
											<!-----search-main------>
										</div>
									</div>
									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										:server-items-length="applicationsMeta.total"
										@update:options="pagination($event)"
									>
										<!---------------1-------------->
										<template v-slot:item.name="{ item }">
											<a class="tb__table--link" v-html="item.name"></a>
										</template>
										<!---------------2-------------->
										<template v-slot:item.status="{ item }">
											<a class="tb__table--link">
												{{ item.status ? item.status : 'Создан' }}
											</a>
										</template>
										<!---------------3-------------->
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<!-------------
                      <a :href="'1-tir-rh' + `/${item.user_id}`">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      --------------------->
												<a
													class="tb__table--btn tb__table--list"
													@click="loadReportPdf(item)"
													v-tooltip.top-center="{
														content: $t('button.download'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.382 11.927v3a1.5 1.5 0 01-1.5 1.5h-10.5a1.5 1.5 0 01-1.5-1.5v-3M5.882 8.177l3.75 3.75 3.75-3.75M9.632 11.927v-9" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</a>
												<button
													@click="$modal.show('modal_reject')"
													class="item__remove"
													v-tooltip.top-center="{
														content: $t('commands.reject'),
														class: ['tooltip__btn'],
													}"
												>
													<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M237.8 1C176.6 5.5 119.4 31.9 75.6 75.6c-100.2 100.2-100.2 260.6 0 360.8 37.7 37.7 85.9 62.8 137.4 71.5 36.3 6.2 70.7 4.9 105.6-3.9 19.2-4.9 31.2-9.3 50-18.6 25.8-12.6 46.8-27.7 67.9-48.9 21.2-21.1 36.3-42.1 48.9-67.9 9.3-18.8 13.7-30.8 18.6-50 6.1-24 7.4-35.5 7.4-62.6 0-17.6-.4-26.5-1.8-35.5-2.3-15.1-8.3-39.1-13.1-52.1-8.7-23.7-25.5-53.2-41.5-73-8.2-10.1-28.3-30.2-38.4-38.4-19.8-16-49.3-32.8-73-41.5-13.1-4.8-37.2-10.9-51.6-13-14-2-38.3-2.7-54.2-1.5zm47.4 62.4c19.5 3.1 35.1 7.9 53.5 16.6 11.9 5.7 29.3 16.2 29.3 17.7 0 .5-60.9 61.7-135.4 136.2L97.2 369.2l-5.1-7.8c-6.7-10.3-16.3-29.7-20-40.6-7.2-20.7-10.2-37.1-10.8-59.8-1.3-44.7 12.1-86.5 39.1-122.5 7.7-10.3 27.8-30.4 38.1-38.1 26.4-19.8 55.6-32.1 89.5-37.5 13.7-2.2 41.4-2 57.2.5zm135.4 89.1c10.7 16.5 20.1 38.6 24.9 59 7 29.3 6.8 62.2-.6 92-11.7 47.4-44 91.6-85.8 117.5-23.1 14.3-46.4 23-74.1 27.7-14.8 2.4-46.9 2.5-60 0-28.1-5.2-52.9-14.8-74.4-28.8l-7.8-5.1 135.3-135.4C352.6 204.9 413.8 144 414.3 144c.4 0 3.2 3.8 6.3 8.5z" fill="red"/></svg>
												</button>

												<!--<a :href="'/'+ $i18n.locale +'/account/reporting/1/item/' + item.id" class="tb__table--btn tb__table--view" v-tooltip.top-center="{content:'Просмотр', class: ['tooltip__btn']}">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>-->
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>

							<modal
								class="modal__block modal__block--middle modal__block--delete"
								name="modal_reject"
								:width="550"
								:min-width="550"
								height="auto"
							>
								<div class="modal__block--wrapper">
									<div class="modal__block--close" @click="$modal.hide('modal_reject')">
										<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</div>
									<div class="modal__wrapper">
										<div class="modal__header">
											<div class="modal__title">
												{{ $t('messages.reject_report') }}<br />
												<strong>
													{{ applications.name }}
												</strong>
											</div>
										</div>

										<div class="modal__content">
											<div class="modal__form">
												<div class="block__row">
													<div class="block__row--left">
														<a class="block__item" @click="removeReport(item.id)">
															<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.reject') }}
														</a>
													</div>
													<div class="block__row--right">
														<a
															class="block__item block__item--blue"
															@click="$modal.hide('modal_reject')"
														>
															{{ $t('button.cancel') }}
														</a>
													</div>
												</div>
												<div
													class="form__block--line form__messages"
													v-if="message.status"
													:class="{
														'form__messages--error': message.status != 200,
														'form__messages--success': message.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ message.text }}
														</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</modal>

							<form
								v-show="filteredlist"
								method="POST"
								enctype="multipart/form-data"
								@submit.prevent="onSubmit"
							>
								<hr class="reporting__hr" />

								<div class="reporting-btn">
									<div class="reporting-wrapper">
										<a class="input-btn form__line--btn" @click="activeElement()">
											{{ $t('commands.sign_and_send') }}
										</a>
										<!-----
                      <button
                        type="submit"
                        class="input-btn form__line--btn"
                       
                      >
                        отправить
						<img src="../../../../../assets/img/icon-plus.svg">
                      </button>
					  ----->
										<!-- <a class="input-btn form__line--btn" @click="$modal.show('modal_head')" v-if="statusPage == 'show'">
                        {{ $t('button.ecp') }}
                      </a> -->
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Pond from '@/components/components/Inputs/pond'
import { api, urlApi } from '@/boot/axios'
import quarter from '@/components/components/Inputs/quarter'
import moment from 'moment'

import { loadPDf } from '@/helpers//index'

import HeaderBack from '@/components/UI/HeaderBack.vue'

export default {
	components: {
		HeaderBack,
		Pond,
		quarter,
	},

	data() {
		return {
			test: ['jon', 'mike', 'jana', 'andre', 'vitaly'],
			urlApi: urlApi,
			v_model_name_search: '',
			v_model_region_search: '',
			v_model_pond_search: '',
			v_model_iin_search: '',
			quarter_value: '',
			inputYears: null,
			id: null,
			FormData: null,
			filter: [],
			applications: [],
			applicationsMeta: {},
			dataEcp: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			options: {
				itemsPerPage: 10,
				page: 1,
			},

			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.report_name'), value: 'name', width: '10vw' },
				{ text: this.$t('headers.send_data'), value: 'date_of_submission', width: '7vw' },
				{ text: this.$t('headers.send_period'), value: 'surender_date', width: '10vw' },
				//{ text: "Водоем", value: "pond", width: "8vw" },
				{ text: this.$t('headers.area_region'), value: 'region', width: '12vw' },
				//{ text: 'iin', value: 'iin_bin', width: '5vw' },
				{ text: this.$t('headers.status'), value: 'status', width: '5vw' },
				// { text: this.$t('headers.created_at'), value: 'created_at' },
				// { text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},

	methods: {
		loadReportPdf(item) {
			api
				.get('/subject/pdf/1/' + item.id, {
					responseType: 'arraybuffer',
				})
				.then((response) => {
					if (response) {
						loadPDf(response?.data, response?.headers['content-type'], 'report-' + item.id)
					}
				})
		},
		async removeReport(id) {
			await api.post(`/tir/canceled/1/${id}`)
			this.pagination(this.options)
		},
		activeElement() {
			if (this.$configFile.active) return this.sendEcpKey()
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (e.wasClean) {
				} else {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.sendEcpKey()
					//this.sendEcpKey(id, 'approved-manager');
				}
			}
			//this.sendEcpKey(id, 'approved-manager');
		},
		sendEcpKey() {
			const formData = new FormData()
			formData.append('xml', this.dataEcp || this.$configFile.xml)
			document.querySelector('.preloader').classList.add('active')
			api
				.post('tir/sign/1', formData)
				.then((response) => {
					if (response.status == 200) {
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/reporting/1-tir-rh' })
						document.querySelector('.preloader').classList.remove('active')
						//this.$router.push("/account/reporting/1-tir-rh");
					}
				})
				.catch(() => {})
				.finally(() => {
					document.querySelector('.preloader').classList.remove('remove')
				})
		},

		onSubmit() {
			document.querySelector('.preloader').classList.add('active')

			let formData = new FormData()
			formData.append('_method', 'PUT')
			formData.append('accept', 1)

			api
				.post('tir/send/1', formData)
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					document.querySelector('.preloader').classList.remove('remove')
				})
		},
		downloadFile(id) {
			api
				.get('subject/pdf/2?id=' + id)
				.then((response) => {
					let fileURL = window.URL.createObjectURL(new Blob([response.data]), {
						type: response.headers['content-type'],
					})
					let fileLink = document.createElement('a')
					fileLink.href = fileURL
					const extensions = {
						'application/pdf': 'pdf',
					}
					const contentType = response.headers['content-type']
					const extension = extensions[contentType]
					const filename = 'report.' + extension

					fileLink.setAttribute('download', filename)
					document.body.appendChild(fileLink)

					fileLink.click()
				})
				.catch(() => {})
		},
		pagination(options) {
			this.apiGetReportingList(options.page, options.itemsPerPage)
		},
		apiGetReportingList(page, per_page) {
			api
				.get('tir/list/1', { params: { page, per_page } })
				.then((response) => {
					if (response.data) {
						/*
            response.data.data.push({
              iin_bin: 155,
              name: "Тоо иии",
              pond: "Озеро Куренное",
              region: "Актюбинская область",
            });
			*/
						this.id = response.data.data.id
						this.applications = response.data.data
						this.applicationsMeta = response.data.meta
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
				})
		},
	},
	watch: {
		v_model_name_search(val) {
			this.quarter_value = null
			this.$refs.region.value = null
			this.v_model_region_search = ''
			this.$refs.pond.value = null
			this.v_model_pond_search = ''
			this.$refs.quarter.value = null
			this.v_model_iin_search = ''
			//this.v_model_name_search=''
		},
		v_model_region_search(val) {
			this.quarter_value = null
			//this.$refs.region.value=null;
			//this.v_model_region_search='';
			this.$refs.pond.value = null
			this.v_model_pond_search = ''
			this.$refs.quarter.value = null
			this.v_model_iin_search = ''
			this.v_model_name_search = ''
		},
		v_model_pond_search(val) {
			this.quarter_value = null
			this.$refs.region.value = null
			this.v_model_region_search = ''
			//this.$refs.pond.value=null;
			//this.v_model_pond_search='';
			this.$refs.quarter.value = null
			this.v_model_iin_search = ''
			this.v_model_name_search = ''
		},
		v_model_iin_search(val) {
			this.quarter_value = null
			this.$refs.region.value = null
			this.v_model_region_search = ''
			this.$refs.pond.value = null
			this.v_model_pond_search = ''
			this.$refs.quarter.value = null
			//this.v_model_iin_search='';
			this.v_model_name_search = ''
		},
		quarter_value(val) {
			//this.quarter_value =null;
			this.v_model_region_search = ''
			this.v_model_pond_search = ''
			//this.$refs.quarter.value=null;
			this.v_model_iin_search = ''
			this.v_model_name_search = ''
		},
	},
	computed: {
		filteredlist() {
			const filter_name = this.applications.filter((d) => {
				let name
				let region
				let pond
				let iin

				if (this.v_model_name_search && d.name) {
					let cutTag = d.name.replace(/&quot;/g, '')
					if (cutTag) {
						name = cutTag.toLowerCase().includes(this.v_model_name_search.toLowerCase())
					} else {
						d.name.toLowerCase().includes(this.v_model_name_search.toLowerCase())
					}
				}
				if (this.v_model_region_search && d.region) {
					region = d.region.toLowerCase().includes(this.v_model_region_search.toLowerCase())
				}
				if (this.v_model_pond_search && d?.pond) {
					pond = d.pond.toLowerCase().includes(this.v_model_pond_search.toLowerCase())
				}

				if (this.v_model_iin_search) {
					if (d['iin_bin'] == Number(this.v_model_iin_search)) {
						return true
					}
				}

				if (this.quarter_value) {
					const format_array = this.quarter_value.split(',')
					const month_number = format_array[0]
					const years = format_array[1]
					const format_month = moment().years(years).month(Number(month_number))
					let start_quarter = format_month.startOf('month').format('x')
					let end_quarter = format_month.add('month', 2).endOf('month').format('x')

					if (d.date_of_submission) {
						let quarter = moment(d.date_of_submission, 'DD,MM,YYYY').format('x')
						quarter = Number(quarter)

						if (quarter > start_quarter && quarter < end_quarter) {
							return true
						}
					}
				}

				if (name || region || pond || iin) {
					return true
				}
			})

			if (
				this.v_model_name_search ||
				this.v_model_region_search ||
				this.v_model_pond_search ||
				this.quarter_value ||
				this.v_model_iin_search
			) {
				if (this.v_model_region_search === 'Все...') {
					return this.applications
				}
				if (this.v_model_pond_search === 'Все...') {
					return this.applications
				}
				if (parseInt(this.quarter_value)) {
					if (parseInt(this.quarter_value) == 177) {
						return this.applications
					}
				}
			} else {
				return this.applications.filter((d) => {
					return true
				})
			}
			if (filter_name.length > 0) {
				return filter_name
			}
		},
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
}
</script>
<style scoped>
.reporting-btn .input-btn.form__line--btn {
	background: #52a5fc;
	width: 250px;
	height: 47px;
	padding: 0;
	justify-content: center;
	transition: background 1s;
}
/*filter*/
.checkbox {
	display: flex;

	align-items: center;
	align-content: center;
	padding: 10px 0;
}

.custom-checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.custom-checkbox + label {
	width: 200px;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;

	color: #0a091d;

	cursor: pointer;
	display: inline-flex;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.custom-checkbox + label::before {
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	flex-shrink: 0;
	flex-grow: 0;
	border-radius: 6px;

	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	border: 1.5px solid #52a5fc;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox > input:not(:disabled):not(:checked) + span:hover::before {
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox > input:not(:disabled):active + span::before {
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox > input:focus + span::before {
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox > input:focus:not(:checked) + span::before {
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox > input:checked + span::before {
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox > input:disabled + span::before {
}

.table__block--filter {
	display: block;
	padding: 30px;
}
.search-main {
	width: 100%;
}
.table__filter--search {
	width: 100%;
	margin: 0;
}
.search__label {
	display: block;
	width: 100%;
}
.table__filter--search label input {
	width: 100%;
}
</style>
