<template>
	<div
		class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] overflow-y-auto max-h-[470px] scrollbar-blue"
	>
		<template v-if="data">
			<div class="w-full flex justify-between items-center gap-[10px] mb-[24.18px]">
				<div class="font-main font-semibold text-[18px] text-third uppercase">
					{{ $t('titles.activity_log') }}
				</div>
			</div>
			<div>
				<div
					v-for="(item, itemInd) in data"
					:key="item.id"
					:class="{ 'mb-[10px]': itemInd < data.length - 1 }"
					class="border-b-[1px] border-b-solid border-b-[#D3E7FB] pb-[20.5px] flex justify-between items-center gap-[58px]"
				>
					<div class="flex items-center gap-[10px]">
						<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[24px] h-[24px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.97 2.5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.47-10-10-10zm3.75 10.35L12 17.08l-.44.5c-.61.69-1.11.51-1.11-.42V13.2h-1.7c-.77 0-.98-.47-.47-1.05L12 7.92l.44-.5c.61-.69 1.11-.51 1.11.42v3.96h1.7c.77 0 .98.47.47 1.05z" fill="#52A5FC"/></svg>
						<span class="font-main font-medium text-[16px] text-third">{{ item.message }}</span>
					</div>
					<div class="font-main font-medium text-[14px] text-second">
						{{ `${new Date(item.created_at).toLocaleTimeString()}` }}
					</div>
					<div class="font-main font-medium text-[14px] text-[#00DEA3]">
						{{ `${new Date(item.created_at).toLocaleDateString()}` }}
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	name: 'ActionsJournal',
	data() {
		return {
			data: null,
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				const response = await api.get('/actionlog/dashboard')

				if (response?.data) {
					this.data = response?.data
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>
