<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !list.length }">
		<v-preloader v-if="!list.length" :message="errorMessage"></v-preloader>

		<div class="container" v-if="list.length">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('handbooks.Области') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												to="regions/create"
												class="btn--link btn--link--plus"
											>
												{{ $t('button.create') }}
												<img src="@/assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="getPageItem"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										:server-items-length="filteredlist.length"
									>
										<template v-slot:item.title="{ item }">
											{{ item.title }}
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													v-if="$store.getters.userPermission(['change_catalog'])"
													:to="String('regions/' + item.id)"
													class="tb__table--btn tb__table--view"
													v-tooltip.top-center="{
														['content']: $t('tooltip.look'),
														['class']: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<router-link
													v-if="$store.getters.userPermission(['change_catalog'])"
													:to="String('regions/' + item.id + '/edit')"
													class="tb__table--btn tb__table--edit"
													v-tooltip.top-center="{
														['content']: $t('tooltip.edit'),
														['class']: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 15h6.75M12.375 2.625a1.591 1.591 0 112.25 2.25L5.25 14.25l-3 .75.75-3 9.375-9.375z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: {
				itemsPerPage: 5,
				page: 1,
			},
			filters: {
				search: '',
			},
			errorMessage: {
				status: null,
				text: null,
			},
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.name'), value: 'title' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	watch: {
		filters: {
			handler() {
				this.options = {
					...this.options,
					page: 1,
				}
			},
			deep: true,
		},
	},
	computed: {
		list() {
			return this.$store.state.handbooks.regions
		},
		filteredlist() {
			return this.list
				.filter((item) =>
					item.title.toLowerCase().startsWith(this.filters.search.toLowerCase().trim()),
				)
				.sort((a, b) => a.id - b.id)
		},
		getPageItem() {
			const startIndex = (this.options.page - 1) * this.options.itemsPerPage
			const lastIndex = startIndex + this.options.itemsPerPage
			return this.filteredlist.slice(startIndex, lastIndex)
		},
	},
	methods: {
		apiGetList() {
			this.$store.dispatch('_fetchRegions').catch((error) => {
				if (error?.response?.status == 500) {
					this.errorMessage.status = 500
					this.errorMessage.text = this.$t('system_message.500')
				}
				if (error?.response?.status == 401) {
					this.errorMessage.status = 401
					this.errorMessage.text = error.response.data.error_message
					this.$router.push('/' + this.$i18n.locale + '/login')
				}
				if (error?.response?.status == 422) {
					this.errorMessage.status = 422
					this.errorMessage.text = error.response.data.error_message
				}
			})
		},
	},
	beforeMount() {
		this.apiGetList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('handbooks.Области') + ' | ' + this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
}
</script>
