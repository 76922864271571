<template lang="">
	<div class="card__content">
		<HeaderBack :back="back" :title="$t('sidebar.elicense_permission')" />
		<div class="card__content--body">
			<div class="mb-3">
				<button
					class="user-profile--update"
					@click="
						$emit('reload', {
							itemsPerPage: options.itemsPerPage,
							page: 1,
						})
					"
				>
					<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					{{ $t('commands.update') }}
				</button>
			</div>
			<div class="table__block--content">
				<v-data-table
					:headers="headers"
					:items="data"
					:loading="false"
					:options.sync="options"
					:item-class="'row_classes'"
					:footer-props="{
						'items-per-page-options': [5, 10, 20, 30, 40, 50],
					}"
					:server-items-length="total"
					@update:options="$emit('paginate', $event)"
				>
					<template v-slot:item.title="{ item }">
						<a class="tb__table--link">
							<span v-html="item.title"></span>
						</a>
					</template>
					<template v-slot:item.action="{ item }">
						<div class="flex">
							<router-link
								v-if="item.region && item.reservoir"
								:to="'/' + $i18n.locale + '/account/fishing-permit/' + `${item.id}`"
							>
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</router-link>
							<router-link
								:to="'/' + $i18n.locale + '/account/fishing-permit/' + `${item.id}/edit`"
								style="margin-left: 15px"
							>
								<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#52A5FC"><path d="M37 4.5c-1.2 1.4-.9 2.1 1.8 4.7 2.6 2.7 3.3 3 4.7 1.8 1-.8 1.5-2.5 1.3-4.4-.3-2.7-.7-3.1-3.4-3.4-1.9-.2-3.6.3-4.4 1.3zM4.4 9.1c-1.7 1.9-1.9 3.8-1.9 17 0 20.1-.7 19.4 19.5 19.4 19.2 0 19.3 0 19.8-14.2.2-5.8-.1-10.4-.8-11.3-1.9-2.8-3 1.1-3 11.3 0 9.5-.1 9.8-2.6 10.7-3.3 1.3-23.5 1.3-26.9 0l-2.6-1 .3-15.2.3-15.3 9-.2C25.7 10 29 9.4 29 8c0-.6-4.6-1-11.3-1C7.4 7 6.1 7.2 4.4 9.1z"/><path d="M25.8 15.8c-6 6.2-9.2 12.2-7.4 13.9 1.7 1.7 8.6-2.1 14.6-8.1l6.4-6.6-2.9-3c-1.6-1.6-3.2-3-3.5-3-.4 0-3.6 3-7.2 6.8z"/></g></svg>
							</router-link>
						</div>
					</template>
					<v-alert slot="no-results" :value="true" color="error">
						{{ $t('system_message.search') }}
					</v-alert>
				</v-data-table>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBack from '@/components/UI/HeaderBack.vue'

export default {
	props: {
		data: {
			type: Array,
		},
		total: {
			type: Number,
		},
		back: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['reload', 'paginate'],
	components: { HeaderBack },
	data() {
		return {
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.fish_plan_region'), value: 'fishingRegionPlan', width: '20vw' },
				{ text: this.$t('headers.spond_name'), value: 'PondName', width: '15vw' },
				{ text: this.$t('headers.fishers'), value: 'fishingPersons', width: '10vw' },
				{ text: this.$t('headers.fishing_type'), value: 'fishingType', width: '10vw' },
				// { text: 'Дата контракта', value: 'contractDate', width: '10vw' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '50px',
				},
			],
			options: {
				itemsPerPage: 5,
				page: 1,
			},
		}
	},
}
</script>

<style scoped>
.card__content {
	margin-bottom: 45px;
}

.card__content--body tr {
	border-bottom: 1px solid #000;
}

.table__block-empty {
	white-space: pre;
}
</style>
