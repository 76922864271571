<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['MonitoringPage']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="$t('sidebar.monitoring-subjects')" />

							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="search-main">
												<div class="row">
													<div class="col-md-3">
														<region
															ref="region"
															:name="'region_id'"
															:allOptions="false"
															:multiple="false"
															:title="''"
															:placeholder="$t('placeholder.search_by_region')"
															v-on:update:modelValue="filter.region_id = $event"
														>
														</region>
													</div>

													<div class="col-md-3">
														<pond
															ref="pond"
															:multiple="false"
															:title="''"
															:required="true"
															:allOptions="false"
															:placeholder="$t('placeholder.pond')"
															v-on:update:modelValue="filter.reservoir_id = $event"
														>
														</pond>
													</div>

													<div class="col-md-3">
														<div class="form__line--input">
															<input
																v-model="filter.iin_bin"
																:placeholder="$t('headers.iin_bin')"
																type="text"
																required=""
																name="iin"
															/>
														</div>
													</div>

													<div class="col-md-3">
														<Fish
															:multiple="true"
															:placeholder="$t('headers.select_fish')"
															v-on:update:modelValue="filter.fishes_id = $event"
														/>
													</div>

													<div class="col-md-3 mt-3">
														<StateList
															:placeholder="$t('headers.records_type')"
															v-on:update:modelValue="filter.status = $event"
														/>
													</div>

													<div class="col-md-3 mt-3">
														<div class="monitoring__line--date">
															<div class="monitoring__date--item w-full">
																<div class="monitoring__date--text">
																	{{ $t('filters.after') }}
																</div>
																<div class="monitoring__date--input w-full">
																	<input
																		type="date"
																		v-model="filter.start_at"
																		class="date__input w-full"
																	/>
																</div>
															</div>
														</div>
													</div>

													<div class="col-md-3 mt-3">
														<div class="monitoring__line--date">
															<div class="monitoring__date--item w-full">
																<div class="monitoring__date--text">
																	{{ $t('filters.before') }}
																</div>
																<div class="monitoring__date--input w-full">
																	<input
																		type="date"
																		v-model="filter.finish_at"
																		class="date__input w-full"
																	/>
																</div>
															</div>
														</div>
													</div>

													<div class="col-md-3 mt-[12px]">
														<div class="form__btns">
															<button class="input-btn form__line--btn" @click="search">
																{{ $t('button.find') }}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="subjects"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										:server-items-length="total"
										:single-expand="true"
										item-key="id"
										show-expand
										class="table__analytic"
										@update:options="pagination($event)"
									>
										<template v-slot:expanded-item="{ item }">
											<td
												:colspan="headers.length + 1"
												style="padding: 0; border-radius: 10px !important"
												class="sub-table"
											>
												<v-data-table
													:headers="headersSubject"
													:items="item.fish"
													:loading="false"
													:single-expand="false"
													item-key="fish_id"
													hide-default-footer
													class="table__analytic px-9-2"
												/>
											</td>
										</template>

										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>

							<ReportManageErrorModal :message="message" :errorMessage="errorMessage" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

import HeaderBack from '@/components/UI/HeaderBack.vue'

import ReportManageErrorModal from '@/components/reporting-management/ReportManageErrorModal.vue'

import Region from '@/components/components/Inputs/region'
import Pond from '@/components/components/Inputs/pond'
import Fish from '@/components/components/Inputs/fish'
import StateList from '@/components/UI/molecules/StateList'

export default {
	name: 'MonitoringPage',
	components: {
		HeaderBack,
		ReportManageErrorModal,
		Region,
		Pond,
		Fish,
		StateList,
	},
	data() {
		return {
			subjects: [],
			total: 0,
			headers: [
				{
					text: this.$t('labels.subject'),
					value: 'name_company',
					width: '200px',
					sortable: false,
				},
				{
					text: this.$t('labels.quato'),
					value: 'sum_total_fish_quota',
					width: '100px',
					sortable: false,
				},
				{
					text: this.$t('labels.permission_for_catch'),
					value: 'sum_total_fish_permission',
					width: '150px',
					sortable: false,
				},
				{ text: '%', value: 'percentage_quota_permission', width: '70px', sortable: false },
				{
					text: this.$t('labels.view_journal'),
					value: 'sum_total_fish_trade',
					width: '150px',
					sortable: false,
				},
				{ text: '%', value: 'percentage_trade_permission', width: '70px', sortable: false },
				{
					text: this.$t('labels.customs'),
					value: 'astana_service_models',
					width: '150px',
					sortable: false,
				},
			],
			headersSubject: [
				{
					text: '',
					value: 'empty',
					width: '56px',
					sortable: false,
				},
				{
					text: this.$t('labels.subject'),
					value: 'fishName',
					width: '200px',
					sortable: false,
				},
				{
					text: this.$t('labels.quato'),
					value: 'quota_total_value',
					width: '100px',
					sortable: false,
				},
				{
					text: this.$t('labels.permission_for_catch'),
					value: 'permission_total_value',
					width: '150px',
					sortable: false,
				},
				{
					text: '%',
					value: 'percentage_quota_permission',
					width: '70px',
					sortable: false,
				},
				{
					text: this.$t('labels.view_journal'),
					value: 'trade_total_value',
					width: '150px',
					sortable: false,
				},
				{
					text: '%',
					value: 'percentage_trade_permission',
					width: '70px',
					sortable: false,
				},
				{
					text: this.$t('labels.customs'),
					value: 'astana_service_models',
					width: '150px',
					sortable: false,
				},
			],
			filter: {
				status: null, // new | active | deleted
				region_id: null,
				reservoir_id: null,
				start_at: null, // 2024-12-05
				finish_at: null, // 2024-12-05
				iin_bin: null,
				fishes_id: [],
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	beforeCreate() {
		if (!localStorage.token) this.$router.push('/ru/login')
	},
	async beforeMount() {
		await this.fetchAnalytic()
	},
	methods: {
		pagination(options) {
			this.fetchAnalytic(options.page, options.itemsPerPage)
		},
		search() {
			this.options.page = 1
			this.pagination(this.options)
		},
		async fetchAnalytic(page, per_page) {
			const params = { page, per_page }
			if (this.filter.status) params['filter[status]'] = this.filter.status
			if (this.filter.region_id) params['filter[region_id]'] = this.filter.region_id
			if (this.filter.reservoir_id) params['filter[reservoir_id]'] = this.filter.reservoir_id
			if (this.filter.iin_bin) params['filter[iin_bin]'] = this.filter.iin_bin
			if (this.filter.start_at) params['filter[start_at]'] = this.filter.start_at
			if (this.filter.finish_at) params['filter[finish_at]'] = this.filter.finish_at
			this.filter.fishes_id.forEach((fishId) => {
				params['filter[fishes_id][]'] = fishId
			})
			try {
				const response = await api.get(`/analytics/trade/monitoring`, {
					params,
				})

				if (response?.data?.data) {
					this.subjects = response.data.data
					this.total = response.data.meta.total
				}
			} catch (error) {
				this.checkError(error)
			}
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
	},
}
</script>

<style scoped>
.table__block--filter {
	display: block;
	padding: 30px;
	margin-bottom: 0;
}

.search-main {
	width: 100%;
}

::v-deep .search-main .input-body .multiselect__tags {
	border: 1px solid #d3e7fb;
}

.form__line--input input {
	width: 100%;
	box-sizing: border-box;
	padding-left: 20px;
	border: 1px solid #d3e7fb;
	border-radius: 5px;
	background: #ffffff;
	font-weight: 400;
	font-size: 14px;
	color: #0a091d;
	line-height: 1.2;
	min-height: 41px;
}

.sub-table ::v-deep .v-data-table-header th {
	height: 0;
	max-height: 0;
	padding-top: 0;
	padding-bottom: 0px;
	box-sizing: border-box;
	background: transparent;
}

.sub-table ::v-deep .v-data-table-header th:first-child {
	border-right: none;
}

.sub-table ::v-deep .v-data-table-header th span {
	color: transparent;
	max-height: 0;
	position: relative;
	box-sizing: border-box;
}
</style>
