<template>
	<button
		:class="{ [getClasses]: true }"
		class="inline-flex justify-between items-center cursor-pointer outline-none font-main font-semibold leading-[1.22]"
		@click="$emit('click')"
	>
		<slot name="prepend" />
		<slot />
		<slot name="append" />
	</button>
</template>

<script>
export default {
	name: 'CustomButton',
	props: {
		type: {
			type: String,
			default: 'primary',
		},
		variant: {
			type: String,
			default: 'filled',
		},
		size: {
			type: String,
			default: 'medium',
		},
	},
	emits: ['click'],
	data() {
		return {
			classes: {
				'primary-filled-medium':
					'bg-main py-[15px] px-[30px] rounded-[10px] gap-[10px] text-[14px] text-white',
			},
		}
	},
	computed: {
		getClasses() {
			return this.classes[`${this.type}-${this.variant}-${this.size}`] || ''
		},
	},
}
</script>
