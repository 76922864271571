<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications || !$store.state.userObject }"
	>
		<v-preloader v-if="!$store.state.userObject" :message="errorMessage"></v-preloader>

		<div class="container" v-if="$store.state.userObject">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['TradeManagement', 'TradeManagementMain']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('modules_list.Электронный промысловый журнал') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<!--
                      <div class="table__filter--search">
                        <label class="search__label">
                          <input type="text" value="" name="filter_search" v-model="filters.search" placeholder="Поиск по журналу">
                          <button >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                          </button>
                        </label>
                      </div>
                      -->
											<!-- <div class="table__filter--order">
                        <multiselect
                          class="select__status"
                          v-model="filterCategories"
                          :options="orderList"
                          label="name"
                          :clearOnSelect="false"
                          :clear-on-select="false"
                          placeholder="Статус"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">{{
                            option.name
                          }}</template>
                          <template slot="noResult">{{ $t('components.not_found') }}</template>
                        </multiselect>
                      </div> -->
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_trade'])"
												:to="'/' + $i18n.locale + '/account/trade/create'"
												class="btn--link btn--link--plus"
											>
												{{ $t('labels.record_to_journal') }}
												<img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<form class="filters" @submit.prevent="search">
										<div class="row">
											<div class="filters__title">
												<div class="col">{{ $t('labels.filter') }}</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 mb-2 col-md-4 mb-md-0">
												<CatchType
													:title="$t('labels.catch_type')"
													:multiple="false"
													:modelValue="catchType"
													:placeholder="$t('placeholder.choose')"
													v-on:update:modelValue="catchType = $event"
												></CatchType>
											</div>
											<div class="col-12 mb-2 col-md-2 mb-md-0">
												<Brigades
													:title="$t('labels.brigade')"
													:multiple="false"
													:modelValue="brigade"
													:placeholder="$t('placeholder.choose')"
													v-on:update:modelValue="brigade = $event"
												></Brigades>
											</div>
											<div class="col-12 mb-2 col-md-2 mb-md-0">
												<div class="form__line form__line--col" style="gap: 8px">
													<div class="form__line--title" style="margin-top: 0; font-size: 12px">
														{{ $t('labels.from') }}
													</div>
													<div class="form__line--input">
														<input
															v-model="catch_at"
															type="date"
															class="input-linck"
															ref="catch_at"
															name="catch_at"
															value=""
															placeholder="Дата улова"
															style="
																border: 1px solid #d3e7fb !important;
																border-radius: 5px;
																cursor: pointer;
															"
														/>
													</div>
												</div>
											</div>
											<div class="col-12 mb-2 col-md-2 mb-md-0">
												<div class="form__line form__line--col" style="gap: 8px">
													<div class="form__line--title" style="margin-top: 0; font-size: 12px">
														{{ $t('labels.to') }}
													</div>
													<div class="form__line--input">
														<input
															v-model="catch_to"
															type="date"
															class="input-linck"
															ref="catch_to"
															name="catch_to"
															value=""
															placeholder="Дата улова"
															style="
																border: 1px solid #d3e7fb !important;
																border-radius: 5px;
																cursor: pointer;
															"
														/>
													</div>
												</div>
											</div>
											<div class="filters__btn">
												<button
													type="submit"
													class="btn--link btn--link--plus"
													style="margin-bottom: 12px"
												>
													{{ $t('button.find') }}
												</button>
											</div>
											<div class="filters__btn">
												<button
													class="btn--link btn--link--plus"
													style="margin-bottom: 12px"
													@click.prevent.stop="clearFilter"
												>
													{{ $t('button.clear') }}
												</button>
											</div>
										</div>
									</form>

									<v-data-table
										:headers="getHeaders"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.name="{ item }">
											<router-link :to="'/' + $i18n.locale + '/account/trade/' + item.id">
												Улов от {{ item.catch_at | formatOnlyDate }}
											</router-link>
										</template>
										<template v-slot:item.brigade_id="{ item }">
											{{ getBrigadeName(item.brigade_id) }}
										</template>
										<template v-slot:item.status="{ item }">
											<span :class="{ 'tb__status--success': item.status == 'active' }">{{
												$t('statusses.magazins.' + item.status)
											}}</span>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													:to="'/' + $i18n.locale + '/account/trade/' + item.id"
													class="tb__table--btn tb__table--show"
													v-tooltip.top-center="{
														content: $t('tooltip.look'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<template v-if="isMagazinsCanEdit(item)">
													<router-link
														:to="'/' + $i18n.locale + '/account/trade/' + item.id + '/edit'"
														class="tb__table--btn tb__table--show"
														v-tooltip.top-center="{
															content: $t('tooltip.edit'),
															class: ['tooltip__btn'],
														}"
													>
														<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#52A5FC"><path d="M37 4.5c-1.2 1.4-.9 2.1 1.8 4.7 2.6 2.7 3.3 3 4.7 1.8 1-.8 1.5-2.5 1.3-4.4-.3-2.7-.7-3.1-3.4-3.4-1.9-.2-3.6.3-4.4 1.3zM4.4 9.1c-1.7 1.9-1.9 3.8-1.9 17 0 20.1-.7 19.4 19.5 19.4 19.2 0 19.3 0 19.8-14.2.2-5.8-.1-10.4-.8-11.3-1.9-2.8-3 1.1-3 11.3 0 9.5-.1 9.8-2.6 10.7-3.3 1.3-23.5 1.3-26.9 0l-2.6-1 .3-15.2.3-15.3 9-.2C25.7 10 29 9.4 29 8c0-.6-4.6-1-11.3-1C7.4 7 6.1 7.2 4.4 9.1z"/><path d="M25.8 15.8c-6 6.2-9.2 12.2-7.4 13.9 1.7 1.7 8.6-2.1 14.6-8.1l6.4-6.6-2.9-3c-1.6-1.6-3.2-3-3.5-3-.4 0-3.6 3-7.2 6.8z"/></g></svg>
													</router-link>
													<button
														v-tooltip.top-center="{
															content: $t('tooltip.remove'),
															class: ['tooltip__btn'],
														}"
														@click="removeTrade(item.id)"
														class="item__remove"
													>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="18" height="18" style="margin-left:15px" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.56 7.34c0 .83.67 1.5 1.5 1.5h.8v17.72c0 2.73 2.21 4.94 4.93 4.94h12.42c2.72 0 4.93-2.21 4.93-4.94V8.84h.8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.25V4.29C22.69 2.2 20.99.5 18.9.5h-5.8c-2.09 0-3.79 1.7-3.79 3.79v1.55H4.06c-.83 0-1.5.67-1.5 1.5zm18.67 5.76v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5zm-8.92-8.81c0-.44.35-.79.79-.79h5.8c.44 0 .79.35.79.79v1.55h-7.38V4.29zm-1.54 8.81c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1z" fill="red"/></svg>
													</button>
												</template>
											</div>
										</template>
										<template v-slot:item.fish="{ header, item }">
											{{ findFish(item.fishs, header.fish_id) }}
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<div class="totals">
										<div class="totals__title">{{ $t('labels.total') }}</div>
										<div class="totals__value">{{ total }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api, urlApi, apiSecond } from '@/boot/axios'

import CatchType from '@/components/components/Inputs/CatchType'
import Brigades from '@/components/components/Inputs/Brigades'

export default {
	components: {
		CatchType,
		Brigades,
	},
	data() {
		return {
			catchType: null,
			brigade: null,
			catch_at: null,
			catch_to: null,

			total: 0,

			urlApi: urlApi,

			applications: null,
			totalItems: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: [
				{
					status: 'all',
					name: this.$t('labels.all'),
				},
				{
					status: 'active',
					name: 'Отправленные',
				},
				{
					status: 'deleted',
					name: 'Заблокированные',
				},
			],

			headers: [
				{ text: 'Id', value: 'id', width: '20px' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '100px',
				},
				{ text: this.$t('headers.catch_date'), value: 'name', width: '10vw' },
				{ text: 'Бригада', value: 'brigade_id' },
				{ text: this.$t('headers.report_status'), value: 'status' },
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},
	computed: {
		...mapGetters(['_getFishes', '_getBrigades']),
		isMagazinsCanEdit() {
			return (magazin) => {
				return magazin.status !== 'active'
			}
		},
		filteredlist() {
			return (
				this.applications?.filter((d) => {
					if (
						(d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) &&
						(this.filterCategories.status == 'all' || d.status == this.filterCategories.status)
					) {
						return true
					}
				}) || []
			)
		},
		getHeaders() {
			return [
				...this.headers,
				...this._getFishes
					.filter((fish) => {
						return this.filteredlist.some((item) => {
							return item.fishs.some((f) => {
								return f.fish_id === fish.id
							})
						})
					})
					.map((fish) => {
						return { text: fish.name, value: 'fish', fish_id: fish.id, width: '150px' }
					}),
			]
		},
		userObject() {
			return this.$store.state.userObject
		},
		getBrigadeName() {
			return (id) => {
				return this._getBrigades.find((b) => b.id == id)?.title || '-'
			}
		},
	},
	methods: {
		...mapActions(['_fetchFishes', '_fetchBrigades']),
		clearFilter() {
			this.catchType = null
			this.brigade = null
			this.catch_at = null
			this.catch_to = null
			this.apiGetUserTradeList()
		},
		search() {
			this.apiGetUserTradeList()
			this.getTotalID()
		},
		findFish(fishs, id) {
			return fishs.find((fish) => fish.fish_id == id)?.value || '0'
		},
		isEditable(date) {
			return new Date().getTime() - new Date(date).getTime() < 60 * 60 * 1000
		},
		pagination(options) {
			this.apiGetUserTradeList(options.page, options.itemsPerPage)
		},
		getQueries() {
			let queries = ''
			if (this.catchType !== null) {
				queries += 'type_catch=' + this.catchType
			}
			if (this.brigade !== null) {
				queries += '&brigade_id=' + this.brigade
			}
			if (this.catch_at !== null) {
				queries += '&start_at=' + this.catch_at
			}
			if (this.catch_to !== null) {
				queries += '&end_at=' + this.catch_to
			}
			return queries
		},
		apiGetUserTradeList(page = 1, itemsPerPage = 10) {
			let queries =
				`user_id=${this.userObject.id}&page=${page}&per_page=${itemsPerPage}` +
				'&' +
				this.getQueries()

			api
				.get(`trade/list?${queries}`)
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		async removeTrade(id) {
			await apiSecond.delete(`/v2/trade/destroy/${id}`)
			this.apiGetUserTradeList()
		},
		async getTotalID() {
			let queries = `?` + this.getQueries()
			const response = await apiSecond.get(`/v2/trade/getTotalTradeResult${queries}`)
			if (response) {
				this.total = response.data.total
			}
		},
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	async mounted() {
		this.getTotalID()
		await this._fetchFishes()
		await this._fetchBrigades()
	},
	head: {
		title() {
			return {
				inner: 'Электронный промысловый журнал',
			}
		},
		meta: [],
	},
}
</script>

<style scoped>
header .header__row {
	display: flex;
}
.item__remove {
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
}
.filters {
	margin-bottom: 25px;
	border: 1px solid #d3e7fb;
	padding: 25px;
	border-radius: 25px;
}
.filters__title {
	margin-bottom: 20px;
	font-weight: 600;
}

.filters__btn {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.filters__btn button {
	font-size: 14px;
	padding: 17px;
}

.totals {
	margin-top: 25px;
	background: #d3e7fb99;
	padding: 15px 18px;
	border-radius: 15px;
}

.totals__title {
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 8px;
	line-height: 1;
}

.totals__value {
	font-size: 48px;
	font-weight: 700;
	line-height: 1;
}
</style>
