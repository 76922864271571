<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['PermissionManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.permission') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													:placeholder="$t('placeholder.choose')"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
									</div>

									<v-data-table
										class="table__block--wrap"
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.name="{ item }">
											{{ item.first_name }} {{ item.last_name }} {{ item.middle_name }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.is_deleted == 0,
													'tb__status--error': item.is_deleted == 1,
												}"
											>
												{{ item.is_deleted == 0 ? $t('status.active') : $t('status.blocked') }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													:to="'/' + $i18n.locale + '/account/permission/' + item.id"
													class="tb__table--btn tb__table--view"
													v-tooltip.top-center="{
														content: $t('tooltip.look'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
	components: {
		Multiselect,
		axios,
	},
	data() {
		return {
			urlApi: urlApi,

			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.fio'), value: 'name' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
				/*{ text: 'Ф.И.О субъекта рыбного хозяйства', value: 'name'},
        { text: 'Выданные разрешения', value: 'permissions' },
        { text: 'Дата получения разрешения', value: 'data' },
        { text: 'Результаты PDF', value: 'action', align:'center', sortable: false, width: '200px'},*/
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},
	methods: {
		apiGetUserDocumentList() {
			/*this.applications = [
        {
          id: 1,
          name: 'Егизбаев Марат Оспанович',
          permissions: 'Для промыслового рыболовства',
          data: '08.07.2021'
        },
        {
          id: 2,
          name: 'Алтынбеков Дастан Ануарбекович',
          permissions: 'Для озерно-товарного рыбоводного хозяйства',
          data: '08.07.2021'
        },
      ];*/

			api
				.get('permission/list')
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				let full_name = d.first_name + ' ' + d.last_name + ' ' + d.middle_name
				if (
					(((d.first_name
						? d.first_name.toLowerCase().includes(this.filters.search.toLowerCase())
						: true) &&
						(d.last_name
							? d.last_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) &&
						(d.middle_name
							? d.middle_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true)) ||
						(full_name
							? full_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) ||
						(d.iin_bin
							? d.iin_bin.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) ||
						(d.email ? d.email.toLowerCase().includes(this.filters.search.toLowerCase()) : true)) &&
					(this.filterCategories.status == 'all' || d.is_deleted == this.filterCategories.status)
				) {
					return true
				}
			})
		},
	},
	beforeMount() {
		this.apiGetUserDocumentList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
