<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications || !$store.getters.userPermission }"
	>
		<v-preloader
			v-if="!applications || !$store.getters.userPermission"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="applications && $store.getters.userPermission">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['QuotaReverseManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.quotas_reverse') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													placeholder="Год лимита"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
										<div class="table__filter--right"></div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.status == 'active',
													'tb__status--error': item.status == 'deleted',
												}"
											>
												{{ item.status == 'active' ? 'Активный' : '' }}
												{{ item.status == 'deleted' ? 'Заблокирована' : '' }}
												{{ item.status == 'new' ? 'Новый' : '' }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													v-if="$store.getters.userPermission(['read_quota'])"
													:to="'/' + $i18n.locale + '/account/quotas-reverse/' + item.id + '/list'"
													class="tb__table--btn tb__table--view"
													v-tooltip.top-center="{ content: 'Список квот', class: ['tooltip__btn'] }"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 3h1.5A1.5 1.5 0 0115 4.5V15a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 013 15V4.5A1.5 1.5 0 014.5 3H6" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.25 1.5h-4.5a.75.75 0 00-.75.75v1.5a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zM9 8.25h3M9 12h3M6 8.25h.008M6 12h.008" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			applications: null,
			regionArray: [],
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			Categories: 'all',
			filterCategories: this.$t('orders.userList'),
			orderList: [
				{
					status: 'all',
					name: this.$t('labels.all'),
				},
				{
					status: '2022',
					name: '2022',
				},
				{
					status: '2021',
					name: '2021',
				},
				{
					status: '2020',
					name: '2020',
				},
			],

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: 'Наименование квоты', value: 'name', width: '60%' },
				{ text: this.$t('headers.created_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	watch: {
		'$store.state.userObject': {
			immediate: true,
			handler: function () {
				this.apiGetQuotaList()
			},
		},
	},
	methods: {
		apiGetQuotaList() {
			if (this.regionArray) {
				api
					.get('quota-reverse/list', {
						params: {
							regions: this.regionArray,
							status: 'active',
						},
					})
					.then((response) => {
						if (response.data) {
							this.applications = response.data.data
						}
					})
					.catch((error) => {
						if (error?.response?.status == 500) {
							this.errorMessage.status = 500
							this.errorMessage.text = this.$t('system_message.500')
						}
						if (error?.response?.status == 401) {
							this.errorMessage.status = 401
							this.errorMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						}
						if (error?.response?.status == 422) {
							this.errorMessage.status = 422
							this.errorMessage.text = error.response.data.error_message
						}
						if (error?.response?.status == 403) {
							this.errorMessage.status = 403
							this.errorMessage.text = error?.response?.data?.message
						}
					})
			}
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (
					d.name.toLowerCase().includes(this.filters.search.toLowerCase()) &&
					(this.filterCategories.status == 'all' || d.status == this.filterCategories.status) &&
					(this.Categories == 'all' || d.category_id == this.Categories)
				) {
					return true
				}
			})
		},
	},
	beforeMount() {},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: 'Главная',
			}
		},
		meta: [],
	},
}
</script>
