<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['RoleManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.roles') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													:placeholder="$t('placeholder.choose')"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_role'])"
												:to="'/' + $i18n.locale + '/account/roles/create'"
												class="btn--link btn--link--plus"
											>
												{{ $t('commands.create_role') }}
												<img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.description="{ item }">
											{{ $t('roles.' + item.description) }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.status == 'active',
													'tb__status--error': item.status == 'deleted',
												}"
											>
												{{ $t('status.' + item.status) }}
											</a>
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<!--
                      <a class="tb__table--btn tb__table--view" @click="$modal.show('modal_success')" v-tooltip.top-center="{content:'Активировать', class: ['tooltip__btn']}">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      -->
												<router-link
													v-if="$store.getters.userPermission(['read_role'])"
													:to="'/' + $i18n.locale + '/account/roles/' + item.id"
													class="tb__table--btn tb__table--view"
													v-tooltip.top-center="{
														content: $t('tooltip.look'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<router-link
													v-if="$store.getters.userPermission(['change_role'])"
													:to="'/' + $i18n.locale + '/account/roles/' + item.id + '/edit'"
													class="tb__table--btn tb__table--edit"
													v-tooltip.top-center="{
														content: $t('tooltip.edit'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 15h6.75M12.375 2.625a1.591 1.591 0 112.25 2.25L5.25 14.25l-3 .75.75-3 9.375-9.375z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<a
													v-if="$store.getters.userPermission(['change_role'])"
													class="tb__table--btn tb__table--delete"
													@click="$modal.show('modal_delete'), (blockElemet = item)"
													v-tooltip.top-center="{
														content: $t('commands.block_role'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</a>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<modal
										class="modal__block modal__block--middle modal__block--delete"
										name="modal_delete"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_delete')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														{{ $t('messages.are_you_sure_to_block_role') }} <br />
														<strong>
															{{ blockElemet ? $t('roles.' + blockElemet.description) : '' }}
														</strong>
													</div>
												</div>

												<div class="modal__content">
													<div class="modal__form">
														<div class="block__row">
															<div class="block__row--left">
																<a
																	class="block__item"
																	v-if="blockElemet"
																	@click="blockElement(blockElemet.id)"
																>
																	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	{{ $t('button.deactivate') }}
																</a>
															</div>
															<div class="block__row--right">
																<button
																	class="block__item block__item--blue"
																	@click.prevent="$modal.hide('modal_delete')"
																>
																	{{ $t('button.cancel') }}
																</button>
															</div>
														</div>
														<div
															class="form__block--line form__messages"
															v-if="message.status"
															:class="{
																'form__messages--error': message.status != 200,
																'form__messages--success': message.status == 200,
															}"
														>
															<label class="form__block--input">
																<span class="form__block--title">
																	{{ message.text }}
																</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</modal>
									<modal
										class="modal__block modal__block--middle modal__block--success"
										name="modal_success"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_success')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														Вы уверены что хотите заблокировать пользователя
													</div>
												</div>

												<div class="modal__content">
													<div class="modal__form">
														<div class="modal__form--message message__center">
															Вы успешно активировали роль
														</div>
													</div>
												</div>
											</div>
										</div>
									</modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			Categories: 'all',
			filterCategories: this.$t('orders.userList'),
			orderList: [
				{
					status: 'all',
					name: this.$t('labels.all'),
				},
				{
					status: 'active',
					name: this.$t('status.active'),
				},
				{
					status: 'deactive',
					name: this.$t('status.blocked'),
				},
			],
			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.role_name'), value: 'name' },
				{ text: this.$t('headers.role_description'), value: 'description' },
				{ text: this.$t('headers.created_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	methods: {
		blockElement(id) {
			api
				.post('user/role/' + id + '/deactivate', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						// this.message.status = 200;
						// this.message.text = response.data.message;

						this.apiGetRoleList()

						this.blockElemet = null
						this.$modal.hide('modal_delete')
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.message.status = 500
						this.message.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.message.status = 401
						this.message.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.message.status = 422
						this.message.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.message.status = 403
						this.message.text = error?.response?.data?.message
					}
				})
		},

		apiGetRoleList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (
					(d.description.toLowerCase().includes(this.filters.search.toLowerCase()) ||
						(this.$options.filters.formatDate(d.created_at)
							? this.$options.filters
									.formatDate(d.created_at)
									.toLowerCase()
									.includes(this.filters.search.toLowerCase())
							: true)) &&
					(this.filterCategories.status == 'all' || d.status == this.filterCategories.status) &&
					(this.Categories == 'all' || d.category_id == this.Categories)
				) {
					return true
				}
			})
		},
	},
	beforeMount() {
		this.apiGetRoleList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: 'Главная',
			}
		},
		meta: [],
	},
}
</script>
