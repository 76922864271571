<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['AnalyticManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="$t('sidebar.analytics')" />

							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="search-main">
												<div class="row">
													<div class="col-md-4">
														<region
															ref="region"
															:name="'region_id'"
															v-on:update:modelValue="filter_region = $event"
															:allOptions="false"
															:multiple="false"
															:title="''"
															:placeholder="$t('placeholder.search_by_region')"
														>
														</region>
													</div>

													<div class="col-md-4">
														<pond
															:multiple="false"
															:title="''"
															:required="true"
															:allOptions="false"
															ref="pond"
															:placeholder="$t('placeholder.pond')"
															v-on:update:modelValue="filter_pond = $event"
														>
														</pond>
													</div>

													<div class="col-md-4">
														<div class="calendar">
															<quarter
																:title="''"
																ref="quarter"
																:placeholder="$t('placeholder.select_quater')"
																:inputYears="true"
																:allOptions="true"
																:multiple="false"
																v-on:update:modelValue="filter_quarter = $event"
															>
															</quarter>
														</div>
													</div>

													<div class="col-md-6 mt-2">
														<div class="form__btns">
															<button class="input-btn form__line--btn" @click="apiGetAnalytics">
																{{ $t('button.find') }}
															</button>
															<button
																v-if="analytic?.length"
																class="input-btn form__line--btn"
																@click="loadAnalytics"
															>
																{{ $t('button.download_excel') }}
																<img src="@/assets/img/icon-file-w.svg" />
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="analytic"
										:loading="false"
										:single-expand="true"
										item-key="region_id"
										hide-default-footer
										show-expand
										class="table__analytic"
									>
										<template v-slot:expanded-item="{ item }">
											<td
												:colspan="headers.length + 1"
												style="padding: 0; border-radius: 10px !important"
											>
												<v-data-table
													:headers="headersFarms"
													:items="item.farms"
													:loading="false"
													:single-expand="true"
													item-key="farm_id"
													hide-default-footer
													show-expand
													class="table__analytic px-9-2"
												>
													<template v-slot:expanded-item="{ item }">
														<td
															:colspan="headersFarms.length + 2"
															style="padding: 0; border-radius: 10px !important"
														>
															<v-data-table
																:headers="headersFish"
																:items="item.catches"
																:loading="false"
																item-key="id"
																hide-default-footer
																class="table__analytic px-9-2"
															>
															</v-data-table>
														</td>
													</template>
												</v-data-table>
											</td>
										</template>

										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import writeXlsxFile from 'write-excel-file'
import moment from 'moment'

import { api } from '@/boot/axios'

import quarter from '@/components/components/Inputs/quarter'
import Region from '@/components/components/Inputs/region'
import Pond from '@/components/components/Inputs/pond'

import HeaderBack from '@/components/UI/HeaderBack.vue'

export default {
	name: 'FishingLog',
	components: {
		quarter,
		Region,
		Pond,
		HeaderBack,
	},
	data() {
		return {
			analytic: [],
			headers: [
				{
					text: this.$t('headers.region_name'),
					value: 'region_name',
					width: '25%',
					sortable: false,
				},
				{
					text: this.$t('headers.quatos_to_catch'),
					value: 'sum_total_fish_quota',
					width: '25%',
					sortable: false,
				},
				{
					text: this.$t('headers.fact_catch'),
					value: 'sum_total_fish_trade',
					width: '25%',
					sortable: false,
				},
				{ text: '%', value: 'percentage_trade_quota', sortable: false },
			],
			headersFarms: [
				{
					text: this.$t('headers.pond'),
					value: 'farm_name',
					width: '25%',
					sortable: false,
				},
				{
					text: this.$t('headers.quatos_to_catch'),
					value: 'sum_total_fish_quota',
					width: '25%',
					sortable: false,
				},
				{
					text: this.$t('headers.fact_catch'),
					value: 'sum_total_fish_trade',
					width: '25%',
					sortable: false,
				},
				{ text: '%', value: 'percentage_trade_quota', sortable: false },
			],
			headersFish: [
				{
					width: '56px',
					text: '',
					value: '',
					sortable: false,
				},
				{
					text: this.$t('headers.fish'),
					value: 'fishName',
					width: '25%',
					sortable: false,
				},
				{
					// text: this.$t('headers.quatos_to_catch'),
					text: '',
					value: 'sum_total_fish_quota',
					width: '25%',
					sortable: false,
				},
				{
					text: this.$t('headers.fact'),
					value: 'total_value',
					width: '25%',
					sortable: false,
				},
				{ text: '', value: 'percentage_trade_quota', sortable: false },
			],
			filter_region: undefined,
			filter_pond: undefined,
			filter_quarter: undefined,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	methods: {
		getQuaterInterval() {
			if (!this.filter_quarter) return null
			const quarter = moment().month(this.filter_quarter)
			const qu = moment(new Date()).utc().quarter()

			if (this.filter_quarter / 3 + 1 > qu) {
				quarter.subtract('years', 1)
			}

			const start_quarter = quarter.startOf('month').format('YYYY-MM-D')
			const end_quarter = quarter.add('month', 2).endOf('month').format('YYYY-MM-D')

			return [start_quarter, end_quarter]
		},
		async loadAnalytics() {
			const data = [
				[
					{
						value: this.$t('headers.region_name'),
						fontWeight: 'bold',
						backgroundColor: '#007bff',
					},
					{
						value: this.$t('headers.quatos_to_catch'),
						fontWeight: 'bold',
						backgroundColor: '#007bff',
						align: 'center',
					},
					{
						value: this.$t('headers.fact_catch'),
						fontWeight: 'bold',
						backgroundColor: '#007bff',
						align: 'center',
					},
					{ value: '%', fontWeight: 'bold', backgroundColor: '#007bff', align: 'center' },
				],
			]

			this.analytic.forEach((elem) => {
				data.push([
					{ value: elem.region_name },
					{ value: elem.sum_total_fish_quota, align: 'center' },
					{
						value: elem.sum_total_fish_trade,
						align: 'center',
					},
					{ value: elem.percentage_trade_quota, align: 'center' },
				])

				data.push([
					{
						value: this.$t('headers.pond'),
						fontWeight: 'bold',
						backgroundColor: '#8cc4ff',
					},
					{
						value: this.$t('headers.quatos_to_catch'),
						fontWeight: 'bold',
						backgroundColor: '#8cc4ff',
						align: 'center',
					},
					{
						value: this.$t('headers.fact_catch'),
						backgroundColor: '#8cc4ff',
						align: 'center',
					},
					{ value: '%', fontWeight: 'bold', backgroundColor: '#8cc4ff', align: 'center' },
				])

				elem.farms.forEach((farm) => {
					data.push([
						{ value: farm.farm_name },
						{ value: farm.sum_total_fish_quota, align: 'center' },
						{ value: farm.sum_total_fish_trade, align: 'center' },
						{ value: farm.percentage_trade_quota, align: 'center' },
					])

					data.push([
						{ value: this.$t('headers.fish'), fontWeight: 'bold', backgroundColor: '#b5d7fc' },
						{
							// value: this.$t('headers.quatos_to_catch'),
							value: '',
							fontWeight: 'bold',
							backgroundColor: '#b5d7fc',
							align: 'center',
						},
						{
							value: this.$t('headers.fact'),
							fontWeight: 'bold',
							backgroundColor: '#b5d7fc',
							align: 'center',
						},
						{ value: '', fontWeight: 'bold', backgroundColor: '#b5d7fc', align: 'center' },
					])

					farm.catches.forEach((fish) => {
						data.push([
							{ value: fish.fishName },
							{ value: fish.sum_total_fish_quota, align: 'center' },
							{ value: fish.total_value, align: 'center' },
							{ value: fish.percentage_trade_quota, align: 'center' },
						])
					})
				})
			})

			await writeXlsxFile(data, {
				columns: [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 15 }],
				fileName: `${this.$t('sidebar.analytics')}.xls`,
			})
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
		async apiGetAnalytics() {
			try {
				const quater = this.getQuaterInterval()

				const params = {}
				if (this.filter_region) params['filter[region_id]'] = this.filter_region
				if (this.filter_pond) params['filter[reservoir_id]'] = this.filter_pond
				if (quater?.[0]) params['filter[start_quarter]'] = quater[0]
				if (quater?.[1]) params['filter[end_quarter]'] = quater[1]

				const response = await api.get(`analytics/trade/list`, {
					params: params,
				})
				if (response?.data?.data) this.analytic = response.data.data
			} catch (error) {
				this.checkError(error)
			}
		},
	},
	async beforeMount() {
		await this.apiGetAnalytics()
	},
}
</script>

<style scoped>
.table__block--filter {
	display: block;
	padding: 30px;
	margin-bottom: 0;
}

.search-main {
	width: 100%;
}

.form__btns {
	display: flex;
	align-items: center;
	gap: 15px;
}
</style>
