var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm.applications }},[(!_vm.applications)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['ReportingManagement']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('HeaderBack',{attrs:{"title":_vm.$t('reports.name.px-1')}}),_c('div',{staticClass:"card__content--body"},[_c('div',{staticClass:"table__block--content"},[_c('div',{staticClass:"table__block--filter"},[_c('div',{staticClass:"table__filter--left"}),_c('div',{staticClass:"table__filter--right"},[_c('router-link',{staticClass:"btn--link btn--link--plus",attrs:{"to":'/' + _vm.$i18n.locale + '/account/reporting/' + 5 + '-tir-rh' + '/create'}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('img',{attrs:{"src":require("../../../../../assets/img/icon-plus.svg")}})]),(_vm.$store.getters.userPermission(['change_reporting-1rh']))?_c('router-link',{staticClass:"btn--link btn--link--plus",attrs:{"to":'/' + _vm.$i18n.locale + '/account/reporting/' + 5 + '-tir-rh' + '/create'}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('img',{attrs:{"src":require('@/assets/img/icon-plus.svg')}})]):_vm._e()],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredlist,"loading":false,"options":_vm.options,"footer-props":{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										},"server-items-length":_vm.applicationsMeta.total},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.pagination($event)}]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('a',{staticClass:"tb__table--link",domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:"item.archived",fn:function({ item }){return [_c('a',{staticClass:"tb__table--link"},[_vm._v(" "+_vm._s(!item.archived ? 'Создан' : 'В архиве')+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('div',{staticClass:"flex"},[_c('router-link',{attrs:{"to":'/' +
														_vm.$i18n.locale +
														'/account/reporting/' +
														5 +
														'-tir-rh' +
														`/${item.id}`}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z","stroke":"#5ABB5E","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z","stroke":"#5ABB5E","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
														content: _vm.$t('button.download'),
														class: ['tooltip__btn'],
													}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcontent: $t('button.download'),\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tclass: ['tooltip__btn'],\n\t\t\t\t\t\t\t\t\t\t\t\t\t}",modifiers:{"top-center":true}}],staticClass:"tb__table--btn tb__table--list",on:{"click":function($event){return _vm.downloadFile(item.id)}}},[_c('svg',{attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M16.382 11.927v3a1.5 1.5 0 01-1.5 1.5h-10.5a1.5 1.5 0 01-1.5-1.5v-3M5.882 8.177l3.75 3.75 3.75-3.75M9.632 11.927v-9","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('system_message.search'))+" ")])],1)],1)])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }