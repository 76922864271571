<template>
	<div class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px]">
		<div class="pl-[13px] border-l-[4px] border-l-solid border-main">
			<div class="font-main font-bold text-[18px] uppercase text-third leading-[1.22]">
				{{ $t('admin.hello.title') }}
			</div>
			<div class="font-main font-normal text-[14px] text-second leading-[1.22]">
				{{ $t('admin.hello.desc') }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WellCome',
}
</script>
