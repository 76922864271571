<template>
	<div
		v-if="statData"
		class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] flex items-stretch flex-wrap gap-[10px]"
	>
		<template>
			<div
				:style="{
					background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #ACD2F9 0%, #DDEEFF 100%)`,
				}"
				class="rounded-[16px] py-[23px] px-[20px] grow min-h-[207px]"
			>
				<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20" r="20" fill="#52A5FC"/><path d="M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z" fill="#fff"/></svg>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statData.sum_total_fish_quota} кг` }}
				</div>
				<div class="font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px]">
					{{ $t('dashboard.my_quota.application.quota.text') }}
				</div>
			</div>
			<div
				:style="{
					background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #FFF4DE 0%, #FEE8BD 100%)`,
				}"
				class="rounded-[16px] py-[23px] px-[20px] grow"
			>
				<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20" r="20" fill="#52A5FC"/><path d="M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z" fill="#fff"/></svg>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statData.sum_total_fish_permission} кг` }}
				</div>
				<div class="font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px]">
					{{ $t('dashboard.my_quota.application.permission.text') }}
				</div>
			</div>
			<div
				:style="{
					background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #DCFCE7 0%, #EDFFF3 100%)`,
				}"
				class="rounded-[16px] py-[23px] px-[20px] grow"
			>
				<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20" r="20" fill="#52A5FC"/><path d="M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z" fill="#fff"/></svg>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statData.sum_total_fish_trade} кг` }}
				</div>
				<div class="font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px]">
					{{ $t('dashboard.my_quota.application.trade.text') }}
				</div>
			</div>
			<div
				:style="{
					background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #C5E1FF 0%, #B6D9FE 100%)`,
				}"
				class="rounded-[16px] py-[23px] px-[20px] grow"
			>
				<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20" r="20" fill="#52A5FC"/><path d="M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z" fill="#fff"/></svg>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statData.percentage_quota_permission} %` }}
				</div>
				<div
					class="w-full font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px] text-wrap"
				>
					{{ $t('dashboard.my_quota.application.quota_percent.text') }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	name: 'StatData',
	props: {
		userTag: {
			type: String,
		},
	},
	data() {
		return {
			statData: null,
		}
	},
	mounted() {
		this.getStatData()
	},
	methods: {
		async getStatData() {
			try {
				const response = await api.get(`/dashboard/${this.userTag}/1`)

				if (response?.data?.data) {
					this.statData = response?.data?.data
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>
