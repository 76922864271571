<template>
	<div
		v-if="statCount"
		class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] flex items-stretch justify-between flex-wrap gap-[10px]"
	>
		<template>
			<div
				class="rounded-[20px] border-box p-[20px] py-[44.5px] border border-solid !border-[#B8D9FB] grow basis-[200px] md:max-w-[33%]"
			>
				<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20.5" r="20" fill="#B8D9FB"/><path d="M27.21 14.36c-1.04-2.1-3.05-3.65-5.38-4.16-2.44-.54-4.94.04-6.85 1.58a7.973 7.973 0 00-3.01 6.27c0 2.59 1.55 5.3 3.89 6.87v.83c-.01.28-.02.71.32 1.06.35.36.87.4 1.28.4h5.13c.54 0 .95-.15 1.23-.43.38-.39.37-.89.36-1.16v-.7c3.1-2.09 5.05-6.5 3.03-10.56zM23.26 30c-.06 0-.13-.01-.19-.03-2.01-.57-4.12-.57-6.13 0-.37.1-.76-.11-.86-.48a.69.69 0 01.48-.86c2.26-.64 4.64-.64 6.9 0 .37.11.59.49.48.86-.1.31-.38.51-.68.51z" fill="#52A5FC"/></svg>
				<div
					class="w-full font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px] text-wrap"
				>
					{{ $t('dashboard.my_quota.application.permission.text') }}
				</div>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statCount['permissions-doc']}` }}
				</div>
			</div>
			<div
				class="rounded-[20px] border-box p-[20px] py-[44.5px] border border-solid !border-[#FFEBC2] grow basis-[200px] md:max-w-[33%]"
			>
				<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20.5" r="20" fill="#FFEBC2"/><path d="M28.5 19.19h-2.89c-2.37 0-4.3-1.93-4.3-4.3V12c0-.55-.45-1-1-1h-4.24c-3.08 0-5.57 2-5.57 5.57v8.86C10.5 29 12.99 31 16.07 31h7.86c3.08 0 5.57-2 5.57-5.57v-5.24c0-.55-.45-1-1-1zm-8.1 5.39l-1.86 1.86c-.23.23-.55.36-.89.36-.34 0-.65-.13-.89-.36l-1.86-1.86c-.41-.41-.48-1.1-.15-1.58l.85-1.28c.21-.33.65-.56 1.04-.56h2.01c.39 0 .82.23 1.04.56l.86 1.28c.31.48.25 1.17-.15 1.58z" fill="#DBA12A"/><path d="M25.43 17.81c.95.01 2.27.01 3.4.01.57 0 .87-.67.47-1.07-1.44-1.45-4.02-4.06-5.5-5.54-.41-.41-1.12-.13-1.12.44v3.49c0 1.46 1.24 2.67 2.75 2.67z" fill="#DBA12A"/></svg>
				<div
					class="w-full font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px] text-wrap"
				>
					{{ $t('dashboard.my_quota.application.reference.text') }}
				</div>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statCount['reference-doc']}` }}
				</div>
			</div>
			<div
				class="rounded-[20px] border-box p-[20px] py-[44.5px] border border-solid !border-[#E3FEEC] grow basis-[200px] md:max-w-[33%]"
			>
				<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[40px] h-[40px] mb-[11px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="20" cy="20.5" r="20" fill="#E3FEEC"/><path d="M26.69 20.53c-.57-.15-1.24-.23-2.04-.23-1.11 0-1.52.27-2.09.7-.03.02-.06.05-.09.08l-.95 1.01c-.79.85-2.24.85-3.04 0l-.95-1a.382.382 0 00-.09-.09c-.58-.43-.99-.7-2.09-.7-.8 0-1.47.07-2.04.23-2.38.64-2.38 2.53-2.38 4.19v.93c0 2.51 0 5.35 5.35 5.35h7.44c3.55 0 5.35-1.8 5.35-5.35v-.93c0-1.66 0-3.55-2.38-4.19z" fill="#68C286"/><path d="M22.79 11h-5.58c-4.42 0-4.42 2.35-4.42 4.42v3.7c.04-.02.09-.03.13-.04.7-.19 1.49-.28 2.43-.28 1.54 0 2.27.45 2.99 1 .1.07.2.16.29.26l.94.99c.1.11.26.17.43.17.17 0 .33-.06.42-.16l.96-1.01c.08-.08.17-.17.27-.24.74-.56 1.46-1.01 3-1.01.94 0 1.73.09 2.43.28.04.01.09.02.13.04v-3.7c0-2.07 0-4.42-4.42-4.42zm-1.24 6.5h-3.1c-.38 0-.7-.32-.7-.7 0-.39.32-.7.7-.7h3.1c.38 0 .7.31.7.7 0 .38-.32.7-.7.7zm.78-2.79h-4.66a.7.7 0 01-.69-.7c0-.39.31-.7.69-.7h4.66c.38 0 .69.31.69.7a.7.7 0 01-.69.7z" fill="#68C286"/></svg>
				<div
					class="w-full font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px] text-wrap"
				>
					{{ $t('dashboard.my_quota.application.declarations.text') }}
				</div>
				<div class="font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]">
					{{ `${statCount['astana-one']}` }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	name: 'StatCount',
	props: {
		userTag: {
			type: String,
		},
	},
	data() {
		return {
			statCount: null,
		}
	},
	mounted() {
		this.getStatCount()
	},
	methods: {
		async getStatCount() {
			try {
				const response = await api.get(`/dashboard/${this.userTag}/quantity`)

				if (response?.data) {
					this.statCount = response?.data
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>
