<template>
	<div
		v-if="isSubject"
		class="border-box bg-white/90 px-[30px] py-[12px] shadow-main rounded-[10px] backdrop-blur-[10px] border-[1px] border-solid border-red flex flex-wrap justify-between items-center gap-[15px]"
	>
		<div>
			<div
				class="flex items-center gap-[10px] font-main font-bold text-[14px] text-red leading-[1.22] mb-[1px] uppercase"
			>
				<svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[24px] h-[24px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.58 5.85l-5.94-3.43c-.97-.56-2.17-.56-3.15 0L4.56 5.85a3.15 3.15 0 00-1.57 2.73v6.84c0 1.12.6 2.16 1.57 2.73l5.94 3.43c.97.56 2.17.56 3.15 0l5.94-3.43a3.15 3.15 0 001.57-2.73V8.58a3.192 3.192 0 00-1.58-2.73zm-8.26 1.9c0-.41.34-.75.75-.75s.75.34.75.75V13c0 .41-.34.75-.75.75s-.75-.34-.75-.75V7.75zm1.67 8.88c-.05.12-.12.23-.21.33a.99.99 0 01-1.09.21c-.13-.05-.23-.12-.33-.21-.09-.1-.16-.21-.22-.33a.986.986 0 01-.07-.38c0-.26.1-.52.29-.71.1-.09.2-.16.33-.21.37-.16.81-.07 1.09.21.09.1.16.2.21.33.05.12.08.25.08.38s-.03.26-.08.38z" fill="#F74A4A"/></svg>
				<span>{{ $t('labels.attention') }}</span>
			</div>
			<div class="font-main font-normal text-[14px] text-second leading-[1.22]">
				{{ $t('messages.subject_need_reports') }}
			</div>
		</div>

		<CustomButton
			v-if="isHasActiveReport"
			@click="$router.push({ path: `/${$i18n.locale}/account/reporting/` })"
		>
			{{ $t('button.sendReport') }}
			<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[24px] h-[24px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm2.79 10.53l-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l3-3-3-3a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3.53 3.53c.3.29.3.77 0 1.06z" fill="#fff"/></svg>
		</CustomButton>
	</div>
</template>

<script>
import CustomButton from '@/components/UI/atoms/CustomButton.vue'

export default {
	name: 'CheckNewReport',
	components: { CustomButton },
	computed: {
		isSubject() {
			return this.$store?.state?.userObject?.role_id === 3
		},
		isHasActiveReport() {
			return true // there is not api for it
		},
	},
}
</script>
