var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm.$store.state.userObject }},[(!_vm.$store.state.userObject)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),(_vm.$store.state.userObject)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['Notifications']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('div',{staticClass:"card__content-header"},[_c('div',{staticClass:"content__title"},[_c('div',{staticClass:"content__title--element"},[_c('div',{staticClass:"content__title--text"},[_vm._v(" "+_vm._s(_vm.$t('notification.title'))+" ")])])])]),_c('div',{staticClass:"card__content--body"},[_c('div',{staticClass:"table__block--content"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"loading":false,"options":_vm.options,"footer-props":{
										'items-per-page-options': [5, 10, 20, 30, 40, 50],
									}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function({ item, index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.data",fn:function({ item, index }){return [(JSON.parse(item.data).message.link)?_c('a',{staticClass:"pointer",attrs:{"target":"_blank"},on:{"click":function($event){_vm.readSet(index, JSON.parse(item.data).message.link)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', { link: JSON.parse(item.data).message.link }, ))+" ")])]):(
												_vm.$te(
													'notification.' +
														JSON.parse(item.data).message.model +
														'.' +
														JSON.parse(item.data).message.status +
														'.href',
												)
											)?_c('a',{staticClass:"pointer",on:{"click":function($event){_vm.readSet(
													index,
													'/' +
														_vm.$i18n.locale +
														_vm.$t(
															'notification.' +
																JSON.parse(item.data).message.model +
																'.' +
																JSON.parse(item.data).message.status +
																'.href',
															{ id: JSON.parse(item.data).id },
														),
												)}}},[(JSON.parse(item.data).message.iin_bin)?_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', { iin_bin: JSON.parse(item.data).message.iin_bin }, ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', ))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', ))+" ")])]}},{key:"item.read_at",fn:function({ item }){return [_c('a',{staticClass:"tb__table--status",class:{ 'tb__status--success': item.read_at }},[_vm._v(" "+_vm._s(item.read_at ? _vm.$t('template_text.viewed') : _vm.$t('template_text.new'))+" ")])]}},{key:"item.action",fn:function({ item, index }){return [_c('div',{staticClass:"flex"},[(JSON.parse(item.data).message.link)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
													content: _vm.$t('tooltip.go'),
													class: ['tooltip__btn'],
												}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\t\t\tcontent: $t('tooltip.go'),\n\t\t\t\t\t\t\t\t\t\t\t\t\tclass: ['tooltip__btn'],\n\t\t\t\t\t\t\t\t\t\t\t\t}",modifiers:{"top-center":true}}],staticClass:"tb__table--btn pointer",attrs:{"href":JSON.parse(item.data).message.link,"target":"_blank"}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M6.75 15.75h-3a1.5 1.5 0 01-1.5-1.5V3.75a1.5 1.5 0 011.5-1.5h3M12 12.75L15.75 9 12 5.25M15.75 9h-9","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
													content: _vm.$t('tooltip.go'),
													class: ['tooltip__btn'],
												}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\t\t\tcontent: $t('tooltip.go'),\n\t\t\t\t\t\t\t\t\t\t\t\t\tclass: ['tooltip__btn'],\n\t\t\t\t\t\t\t\t\t\t\t\t}",modifiers:{"top-center":true}}],staticClass:"tb__table--btn pointer",on:{"click":function($event){_vm.readSet(
														index,
														'/' +
															_vm.$i18n.locale +
															_vm.$t(
																'notification.' +
																	JSON.parse(item.data).message.model +
																	'.' +
																	JSON.parse(item.data).message.status +
																	'.href',
																{ id: JSON.parse(item.data).id },
															),
													)}}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M6.75 15.75h-3a1.5 1.5 0 01-1.5-1.5V3.75a1.5 1.5 0 011.5-1.5h3M12 12.75L15.75 9 12 5.25M15.75 9h-9","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]}}],null,false,3941665542)})],1)])])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }