<template>
	<div
		v-if="showVideo"
		class="relative bg-white/90 shadow-main rounded-[10px] cursor-pointer overflow-hidden"
		@click="isActive = true"
	>
		<img src="@/assets/img/video-instruction-banner.png" alt="" class="block w-full" />
		<div
			class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-[10px] font-main font-bold text-[16px] text-center text-white uppercase"
		>
			<span>{{ $t('system_message.video_system') }}</span>
			<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block w-[24px] h-[24px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.07 19.82c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06c3.61-3.61 3.61-9.48 0-13.08a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0 4.19 4.19 4.19 11.01 0 15.2-.15.15-.34.22-.53.22zM4.93 19.82c-.19 0-.38-.07-.53-.22C.21 15.41.21 8.59 4.4 4.4c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06-3.61 3.61-3.61 9.48 0 13.08.29.29.29.77 0 1.06-.15.15-.34.22-.53.22zM12 22.71c-1.25-.01-2.44-.21-3.55-.6a.754.754 0 01-.46-.96c.14-.39.56-.6.96-.46.96.33 1.98.51 3.06.51 1.07 0 2.1-.18 3.05-.51.39-.13.82.07.96.46s-.07.82-.46.96c-1.12.39-2.31.6-3.56.6zM15.3 3.34c-.08 0-.17-.01-.25-.04A9.04 9.04 0 0012 2.79c-1.07 0-2.09.18-3.05.51-.4.13-.82-.07-.96-.46s.07-.82.46-.96c1.12-.39 2.31-.59 3.55-.59 1.24 0 2.44.2 3.55.59a.75.75 0 01-.25 1.46zM8.74 12v-1.67c0-2.08 1.47-2.93 3.27-1.89l1.45.84 1.45.84c1.8 1.04 1.8 2.74 0 3.78l-1.45.84-1.45.84c-1.8 1.04-3.27.19-3.27-1.89V12z" fill="#fff"/></svg>
		</div>

		<div
			v-show="isActive"
			class="fixed z-[1100] top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#00000044]"
			@click.stop="isActive = false"
		>
			<div class="relative box-border w-[90%] max-w-[880px] box-border" @click.stop="">
				<div class="w-full box-border aspect-w-8 aspect-h-5">
					<iframe
						src="https://www.youtube.com/embed/0-j-s7gjTFI?si=SYN5XzZLkqq6B8Nl"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin"
						allowfullscreen
					></iframe>
				</div>
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'absolute top-[0.5%] right-[0.5%] block w-[36px] h-[36px] z-[100] cursor-pointer'" @click="isActive = false" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm3.36 12.3c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-2.3-2.3-2.3 2.3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l2.3-2.3-2.3-2.3a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 2.3-2.3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.3 2.3 2.3 2.3z" fill="#fff"/></svg>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VideoBanner',
	data() {
		return {
			isActive: false,
		}
	},
	computed: {
		showVideo() {
			return [3].includes(this.$store?.state?.userObject?.role_id)
		},
	},
}
</script>
