<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !_getRegions || !_getReservoirs || !_getFishes,
		}"
	>
		<v-preloader
			v-if="!_getRegions || !_getReservoirs || !_getFishes"
			:message="errorMessage"
		></v-preloader>

		<div v-else class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['QuotaManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ getTitle }}</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.iin_bin') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.iin_bin
																? 'input--error'
																: '',
														]"
													>
														<TheMask
															v-model="iin_bin"
															:masked="true"
															:name="'iin_bin'"
															:placeholder="$t('headers.iin_bin')"
															:mask="['### ### ### ###']"
															:disabled="isNotEditable"
															type="text"
															class="input-linck"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.iin_bin"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.iin_bin"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.quota_region') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<multiselect
															required=""
															v-model="region_id"
															:options="_getRegions"
															:preserve-search="true"
															:disabled="isNotEditable"
															track-by="id"
															label="title"
															:placeholder="$t('headers.quota_region')"
															class="multiselect__check"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.title
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.pond') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<multiselect
															required=""
															v-model="reservoir_id"
															:options="_getReservoirs"
															:preserve-search="true"
															:disabled="isNotEditable"
															:placeholder="$t('headers.pond')"
															track-by="id"
															label="title"
															class="multiselect__check"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.title
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.year') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.year
																? 'input--error'
																: '',
														]"
													>
														<TheMask
															v-model="year"
															:masked="true"
															:name="'year'"
															:disabled="isNotEditable"
															:placeholder="$t('headers.year')"
															:mask="['####']"
															type="text"
															class="input-linck"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.year"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.year"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">
												{{ $t('headers.composition_catch_fish') }}
											</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-12">
													<div
														class="quota-fish__line"
														v-for="(fish, fishIndex) in fishes"
														:key="fish.id"
													>
														<div class="form__line--input">
															<div class="row align-items-center">
																<div class="col-xl-12">
																	<div
																		class="quota-fish__item"
																		:class="[
																			errorMessage.messages && errorMessage.messages.fish
																				? 'input--error'
																				: '',
																		]"
																	>
																		<div class="row align-items-center">
																			<div class="col-xl-3">
																				<Fish
																					:required="true"
																					:multiple="false"
																					name="fish"
																					:modelValue="fish.fish_id"
																					:disabled="isNotEditable"
																					:placeholder="$t('placeholder.choose')"
																					v-on:update:modelValue="fish.fish_id = $event"
																				/>
																			</div>
																			<div class="col-xl-3">
																				<div class="form__line--input form__line--input-row">
																					<input
																						v-model="fish.value"
																						type="number"
																						step=".01"
																						min="0"
																						:name="
																							'fish[' + fishIndex + '][' + fish.fish_id + '][value]'
																						"
																						:ref="'fish'"
																						:data-id="fish.fish_id"
																						class="input-linck"
																						:placeholder="fish.value"
																						:disabled="isNotEditable"
																						:style="{ display: 'inline-block', width: '80%' }"
																					/>
																					<span> кг.</span>
																				</div>
																			</div>
																			<div class="col-xl-3">
																				<div class="form__line--input form__line--input-row">
																					<input
																						type="text"
																						:value="
																							Number(
																								((fish.value || 0) / 1000).toFixed(6),
																							).toString()
																						"
																						:disabled="true"
																						:style="{ display: 'inline-block', width: '80%' }"
																						class="input-linck"
																					/>
																					<span> т.</span>
																				</div>
																			</div>
																			<div v-if="!isNotEditable" class="col-xl-2">
																				<a v-if="fishes.length > 1" @click="removeFish(fishIndex)">
																					<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																				</a>
																				<button
																					v-if="fishIndex === fishes.length - 1"
																					class="input-btn form__line--btn-sign"
																					@click.stop.prevent="addFishes()"
																				>
																					<img src="../../../assets/img/icon-plus-blue.svg" />
																				</button>
																			</div>
																		</div>
																	</div>
																	<div
																		class="input-required"
																		v-if="errorMessage.messages && errorMessage.messages.fish"
																	>
																		<p>{{ errorMessage.messages.fish[fish.id] }}</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="input__row">
												<button v-if="!isNotEditable" class="input-btn form__line--btn">
													{{ isEdit ? $t('tooltip.edit') : $t('button.save') }}
												</button>
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.status"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>
											<div
												class="form__block--line form__messages"
												v-if="errorMessage.text"
												:class="{
													'form__messages--error': errorMessage.status != 200,
													'form__messages--success': errorMessage.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ errorMessage.text }}
													</span>
												</label>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TheMask } from 'vue-the-mask'

import { api } from '@/boot/axios'
import Multiselect from 'vue-multiselect'
import Fish from '@/components/components/Inputs/fish'

export default {
	components: {
		TheMask,
		Multiselect,
		Fish,
	},
	data() {
		return {
			iin_bin: null,
			region_id: null,
			reservoir_id: null,
			year: null,
			fishes: [
				{
					id: new Date().getTime(),
					value: 0,
					fish_id: null,
				},
			],
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		...mapGetters(['_getRegions', '_getReservoirs', '_getFishes']),
		isNotEditable() {
			return this.$route.meta.isView
		},
		isEdit() {
			return this.$route.meta.isEdit
		},
		getTitle() {
			if (this.isEdit) return this.$t('titles.quota_editing')
			else if (this.isNotEditable) return this.$t('titles.quota_viewing')
			return this.$t('titles.quota_addings')
		},
	},
	mounted() {
		this._fetchRegions()
		this._fetchReservoirs()
		this._fetchFishes()
		this.fetchQuota()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	methods: {
		...mapActions(['_fetchRegions', '_fetchReservoirs', '_fetchFishes']),
		catchError(error) {
			if (error?.response?.status == 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			}
			if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error.response.data.error_messages
				this.$router.push('/' + this.$i18n.locale + '/login')
			}
			if (error?.response?.status == 422) {
				this.errorMessage.status = 422
				this.errorMessage.messages = error.response.data.error_messages
			}
			if (error?.response?.status == 403) {
				this.errorMessage.status = 403
				this.errorMessage.text = error?.response?.data?.message
			}
		},
		fetchQuota() {
			if (this.$route.params.id) {
				api
					.get(`/quota/${this.$route.params.id}`)
					.then((response) => {
						if (response.status == 200) {
							const resData = response.data

							if (resData) {
								this.iin_bin = resData.iin_bin
								this.region_id = resData.region
								this.reservoir_id = resData.pond
								this.year = resData.start_at.split('-').shift()
								this.fishes = resData.fishes.map((fish) => {
									return {
										id: fish.id,
										value: fish.value,
										fish_id: fish.fish_id,
									}
								})
							}
						}
					})
					.catch((error) => {
						this.catchError(error)
					})
			}
		},
		addFishes() {
			this.fishes.push({
				id: new Date().getTime(),
				value: 0,
				fish_id: null,
			})
		},
		removeFish(index) {
			this.fishes.splice(index, 1)
		},
		onSubmit() {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}

			const iin_bin = this.iin_bin?.replaceAll(' ', '') || ''
			if (iin_bin.length !== 12) {
				this.errorMessage.messages = {
					iin_bin: [this.$t('messages.invalid_iin')],
				}
				return
			}

			const sendData = {
				iin_bin: iin_bin,
				region_id: this.region_id?.id,
				reservoir_id: this.reservoir_id?.id,
				year: this.year,
				fishes: this.fishes.map((fish) => {
					return {
						fish_id: fish.fish_id,
						value: fish.value,
					}
				}),
			}

			api
				.post(this.$route.params.id ? `/quota/${this.$route.params.id}` : '/quota/create', sendData)
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/quotas/',
						})
					}
				})
				.catch((error) => {
					this.catchError(error)
				})
		},
	},
}
</script>

<style>
.form__line p {
	margin-bottom: 0;
}
</style>
