<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['UserManagementMain', 'RequestRecovery']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.request_recovery') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('labels.search_applicsation')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.name="{ item }">
											{{ item.first_name }} {{ item.last_name }} {{ item.middle_name }}
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.updated_at | formatDate }}
										</template>
										<template v-slot:item.role_id="{ item }">
											{{ item.role.description }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.count_login_fail == 0,
													'tb__status--error': item.count_login_fail == 3,
												}"
											>
												{{
													item.count_login_fail == 3 ? $t('status.blocked') : $t('status.active')
												}}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<!--
                      <a class="tb__table--btn tb__table--view" :href="'/'+ $i18n.locale +'/account/request_recovery/'+ item.id " v-tooltip.top-center="{content:$t('tooltip.look'), class: ['tooltip__btn']}">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      -->
												<a
													v-if="$store.getters.userPermission(['change_user'])"
													class="tb__table--btn tb__table--delete"
													@click="$modal.show('modal_success'), (unblockElemet = item)"
													v-tooltip.top-center="{
														content: $t('labels.send_new_password'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 3h12c.825 0 1.5.675 1.5 1.5v9c0 .825-.675 1.5-1.5 1.5H3c-.825 0-1.5-.675-1.5-1.5v-9C1.5 3.675 2.175 3 3 3z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.5 4.5L9 9.75 1.5 4.5" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</a>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<modal
										class="modal__block modal__block--middle modal__block--success"
										name="modal_success"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_success')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														{{
															$t('messages.are_you_sure_to_unblock_user', [
																`${
																	unblockElemet && unblockElemet.first_name
																		? unblockElemet.first_name
																		: ''
																} ${
																	unblockElemet && unblockElemet.last_name
																		? unblockElemet.last_name
																		: ''
																} ${
																	unblockElemet && unblockElemet.middle_name
																		? unblockElemet.middle_name
																		: ''
																}`,
															])
														}}<br />
													</div>
												</div>

												<div class="modal__content">
													<div class="modal__form">
														<div class="block__row">
															<div class="block__row--left">
																<a
																	class="block__item block__item--green"
																	v-if="unblockElemet"
																	@click="unblockElement(unblockElemet.id)"
																>
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	{{ $t('button.unblock') }}
																</a>
															</div>
															<div class="block__row--right">
																<a
																	class="block__item block__item--blue"
																	@click="$modal.hide('modal_success')"
																>
																	{{ $t('button.cancel') }}
																</a>
															</div>
														</div>
														<div
															class="form__block--line form__messages"
															v-if="message.status"
															:class="{
																'form__messages--error': message.status != 200,
																'form__messages--success': message.status == 200,
															}"
														>
															<label class="form__block--input">
																<span class="form__block--title">
																	{{ message.text }}
																</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			applications: null,
			totalItems: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			unblockElemet: null,

			menuClick: false,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			Categories: 'all',
			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.fio'), value: 'name' },
				{ text: this.$t('headers.iin'), value: 'iin_bin' },
				{ text: 'Email', value: 'email' },
				{ text: this.$t('headers.register_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	methods: {
		unblockElement(id) {
			api
				.post('user/' + id + '/restore', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.blockElemet = null
						this.$modal.hide('modal_success')

						// this.$router.go();
					}
				})
		},
		pagination(options) {
			this.apiGetBlockedList(options.page, options.itemsPerPage)
		},
		apiGetBlockedList(page = 1, per_page = 10) {
			api
				.get('user/blocked/list', { params: { page, per_page } })
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return (
				this.applications?.filter((d) => {
					let full_name = d.first_name + ' ' + d.last_name + ' ' + d.middle_name
					if (
						(((d.first_name
							? d.first_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) &&
							(d.last_name
								? d.last_name.toLowerCase().includes(this.filters.search.toLowerCase())
								: true) &&
							(d.middle_name
								? d.middle_name.toLowerCase().includes(this.filters.search.toLowerCase())
								: true)) ||
							(full_name
								? full_name.toLowerCase().includes(this.filters.search.toLowerCase())
								: true) ||
							(d.iin_bin
								? d.iin_bin.toLowerCase().includes(this.filters.search.toLowerCase())
								: true) ||
							(this.$options.filters.formatDate(d.created_at)
								? this.$options.filters
										.formatDate(d.created_at)
										.toLowerCase()
										.includes(this.filters.search.toLowerCase())
								: true)) &&
						(this.filterCategories.status == 'all' || d.is_deleted == this.filterCategories.status)
					) {
						return true
					}
				}) || []
			)
		},
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.request_recovery'),
			}
		},
		meta: [],
	},
}
</script>
